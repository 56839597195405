import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const HistorycznyPage = () => (
  <Layout>
    <SEO title="Historyczny Weekend w Gdańsku" />
    <section className="single-trip-description">
      <div className="wrap">
        <div className="negative-margin-left negative-margin-photo">
          <img src={`/static/historyczny-weekend.jpg`} alt="" />
        </div>
        <div>
          <h3>1000 LETNIE MIASTO, KTÓRE JEST PERŁĄ POLSKIEJ HISTORII</h3>
          <h1>Historyczny Weekend w Gdańsku</h1>
          <p>
            Bogata historia miasta Gdańska jest naszą dumą. Zapraszamy do
            odwiedzenia nas i poznania popularnych atrakcji historycznych, w tym
            tych, znanych na skalę światową. Zobaczycie tak zwaną Drogę
            Królewską z jej pięknym Ratuszem, Dworem Artusa, Fontanną Neptuna.
            Niepodważalną perłą historyczną w północnej części Polski jest Zamek
            w Malborku. Jest on największym gotyckim zamkiem na świecie.
            Wejdziecie do zamku, poznacie historię Krzyżaków oraz smak ich
            potraw w restauracji na zamku. Następnego dnia zapraszamy na rejs z
            Gdańska na Westerplatte – miejsce przełomowe dla historii Polski. W
            samym sercu miasta zapraszamy także do zobaczenia Fortów
            Napoleońskich czyli pozostałości fortyfikacji z czasów epoki
            napoleońskiej. Polecamy również wizytę w Europejskim Centrum
            Solidarności, które prezentuje pamiątki z historii komunizmu, a
            także dostarcza szczegółowy opis tego systemu w Europie Wschodniej.
          </p>
        </div>
        <div className="trip-plan">
          <h4>W programie proponujemy:</h4>
          <div className="day">
            <div className="day-number">Dzień 1</div>
            <div className="day-plan">
              <ul>
                <li>Transfer z lotniska / dworca PKP</li>
                <li>Zakwaterowanie w hotelu / pensjonacie / apartamencie</li>
                <li>Zwiedzanie Drogi Królewskiej na Głównym Mieście</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 2</div>
            <div className="day-plan">
              <ul>
                <li>
                  Wyjazd do Malborka, zwiedzanie Zamku i obiad w restauracji
                  zamkowej
                </li>
                <li>Powrót do Gdańska i rejs statkiem na Westerplatte</li>
                <li>Nocleg</li>
              </ul>
            </div>
          </div>
          <div className="day">
            <div className="day-number">Dzień 3</div>
            <div className="day-plan">
              <ul>
                <li>
                  Zwiedzanie Europejskiego Centrum Solidarności oraz Fortów
                  Napoleońskich na Górze Gradowej
                </li>
                <li>Transfer na lotnisko / dworzec PKP</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <ContactForm />
        </div>
        <div className=" prices">
          <div>
            CENA OD: 450 zł / za osobę
            <p> CENA ZAWIERA</p>
            <ul>
              <li>Transfer </li>
              <li>Zakwaterowanie </li>
              <li>Towarzystwo przewodnika turystycznego </li>
            </ul>
          </div>
          <div>
            Cena zależy od ilości osób, programu i standardu noclegu. Cenę
            kalkulujemy dla każdego klienta indywidualnie, ponieważ zależy ona
            od ilości osób, programu i rodzaju noclegu. Każdy program
            dostosowany jest do życzeń naszych gości oraz do ich harmonogramu
            podróży.
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default HistorycznyPage
